<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <h2 class="brand-text text-primary ml-1">
        <myegrn-logo />
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1 mt-3">
          Архив <myegrn-logo /> содержит исторические файлы Росреестра, ЕГРН 🚀
        </h2>
        <p class="mb-3">
          Обменивайтесь КПТ и выписками с коллегами: загружайте свои файлы в наш архив, скачивайте нужные Вам файлы,
          просматривайте Ваши файлы без ограничений. Храните свои XML-файлы в нашем архиве, здесь они не потеряются. Это БЕСПЛАТНО.
          <br><br>
          Присоединяйтесь к нашей партнёрской программе, приглашайте друзей и коллег
          <br><br>
          <span style="color: red"> В состав Архива входит БЕСПЛАТНЫЙ конвертер файлов Росреестра в HTML и PDF (человекочитаемый формат)</span>
          <br><br>
          Остались вопросы? Напишите нам в Telegram бота поддержки <b-link
            class="ml-25"
            href="https://t.me/myegrnbot"
            target="_blank"
          >@MyEgrnBot</b-link> и мы постараемся оперативно на них ответить
        </p>
        <b-button
          variant="primary"
          class="mb-1 mx-1"
          :to="{name:'auth-login'}"
        >
          Вход
        </b-button>
        <b-button
          variant="primary"
          class="mb-1 mx-1"
          :to="{name:'auth-register'}"
        >
          Регистрация
        </b-button>
        <b-img
          fluid
          :src="imgUrl"
          alt="Coming soon page"
        />
        <b-link
          class="ml-25"
          href="/oferta.pdf"
          target="_blank"
        >Оферта</b-link>
        |
        <b-link
          class="ml-25"
          href="/pd.pdf"
          target="_blank"
        >Политика обработки персональных данных</b-link>
      </div>
    </div>
  </div>
<!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BButton, BImg,
} from 'bootstrap-vue'
import MyegrnLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    MyegrnLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/coming-soon.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
